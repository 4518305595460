.timeline-container {
	background-color: #282c34;
	min-height: 95vh;
	padding-top: 55px;
	padding-bottom: 55px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: #82a284;

	h3 {
		margin: auto;
		@media (max-width: 800px) {
			font-size: 1em;
		}
	}

	.present-moment {
		height: 106px;
		max-width: 360px;
		width: calc((100% - 4px - 4px - 22.6px) / 2 - 2px);
		border: 2px solid #82a284;
		border-radius: 6px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&::before {
			content: "";
			display: block;
			position: absolute;
			bottom: -11px + 0.5px;
			left: calc(50% - 11px + 2px);
			height: 16px;
			width: 16px;
			border-bottom: 2px solid #82a284;
			border-right: 2px solid #82a284;
			background-color: #282c34;
			transform: rotate(45deg);
		}
	}

	.timeline {
		min-height: 95vh;
		max-width: 100%;
		width: calc((364px + 11px) * 2 + 4px + 2px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-top: 11px;

		.line {
			height: calc(100% - 110px);
			width: 4px;
			background-color: #82a284;
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
		}

		.event {
			height: 106px;
			max-width: 360px;
			width: calc((100% - 4px - 4px - 22px) / 2 - 2px);
			border: 2px solid #82a284;
			border-radius: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:nth-of-type(even) {
				position: relative;

				&::before {
					content: "";
					display: block;
					position: absolute;
					top: calc(50% - 11px);
					left: -11px + 0.5px;
					height: 16px;
					width: 16px;
					border-top: 2px solid #82a284;
					border-left: 2px solid #82a284;
					background-color: #282c34;
					transform: rotate(-45deg);

					@media (max-width: 800px) {
						left: -11px;
					}
				}
			}

			&:nth-of-type(odd) {
				position: relative;

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: calc(50% - 11px);
					right: -11px + 0.5px;
					height: 16px;
					width: 16px;
					border-top: 2px solid #82a284;
					border-right: 2px solid #82a284;
					background-color: #282c34;
					transform: rotate(45deg);
				}
			}

			&:last-of-type {
				align-self: center !important;

				&::before {
					content: "";
					display: block;
					position: absolute;
					top: -11px;
					left: calc(50% - 11px + 2px);
					height: 16px;
					width: 16px;
					border-top: 2px solid #82a284;
					border-left: 2px solid #82a284;
					background-color: #282c34;
					transform: rotate(45deg);
				}

				&::after {
					display: none;
				}
			}
		}
	}
}
