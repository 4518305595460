.resume-pdf-wrapper {
	background-color: #282c34;
	height: max(95vh, 100%);
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	font-size: calc(10px + 2vmin);
	color: #82a284;
	.resume {
		min-height: 95vh;
	}
	h3 {
		margin: 20px 0;
	}
	img {
		width: 60vw;
		height: auto;
		border: 1.5px solid #82a284;
		border-radius: 4px;
	}
}

@media only screen and (max-width: 675px) {
	.resume-pdf-wrapper {
		img {
			width: 90vw;
		}
	}
}
