nav {
	height: calc(5vh - 1px);
	display: flex;
	align-items: center;
	background-color: #282c34;
	color: #82a284;
	border-bottom: 1px solid #82a284;
	a {
		margin: 0 12px;
		color: #82a284;
		transition: color 0.2s linear;
		&:hover,
		&:focus-visible {
			color: #446a46;
		}
	}
}
